import { DetailsFields } from 'checkout-utils';
import { CheckoutFlow, InformationCardsEnum } from './checkout-flow.interface';

export const eshopCheckoutFlows: CheckoutFlow[] = [
  {
    productClass: 'HFC',
    productFamily: 'INTERNET',
    deliveryDays: 3,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 2,
      },
    ],
    detailsFieldsToHide: [
      DetailsFields.SCAN_ID_CTA,
      DetailsFields.NATIONALITY,
      DetailsFields.ID_TYPE,
      DetailsFields.ID_NUMBER,
      DetailsFields.ID_EXPIRY_DATE,
      DetailsFields.OTO_ID,
      DetailsFields.ESIM,
    ],
    informationCards: [
      { value: InformationCardsEnum.CANCEL_PREVIOUS_CONTRACT, hasCta: false },
      { value: InformationCardsEnum.USE_YOUR_TV_LOGIN, hasCta: false },
    ],
  },
  {
    productClass: 'FIBER',
    productFamily: 'INTERNET',
    deliveryDays: 15,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 2,
      },
    ],
    detailsFieldsToHide: [
      DetailsFields.SCAN_ID_CTA,
      DetailsFields.NATIONALITY,
      DetailsFields.ID_TYPE,
      DetailsFields.ID_NUMBER,
      DetailsFields.ID_EXPIRY_DATE,
      DetailsFields.ESIM,
    ],
    informationCards: [
      { value: InformationCardsEnum.CANCEL_PREVIOUS_CONTRACT, hasCta: false },
      { value: InformationCardsEnum.USE_YOUR_TV_LOGIN, hasCta: false },
      {
        value: InformationCardsEnum.SET_YOUR_OTO_ID_AVAILABLE,
        hasCta: true,
        ctaButtonName: 'set_oto_id_in_selfcare',
        ctaHref: 'set_oto_id_href',
      },
      {
        value: InformationCardsEnum.SET_YOUR_OTO_ID_UNAVAILABLE,
        hasCta: false,
      },
    ],
  },
  {
    productClass: 'MBB',
    productFamily: 'INTERNET',
    deliveryDays: 0,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 1,
      },
    ],
    detailsFieldsToHide: [DetailsFields.ACTIVATION_DATE, DetailsFields.OTO_ID, DetailsFields.ESIM],
    informationCards: [{ value: InformationCardsEnum.SCAN_ID_MBB, hasCta: false }],
  },
  {
    productClass: 'POSTPAID',
    productFamily: 'INTERNET',
    deliveryDays: 0,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 2,
      },
    ],
    detailsFieldsToHide: [DetailsFields.ACTIVATION_DATE, DetailsFields.OTO_ID],
    informationCards: [
      { value: InformationCardsEnum.ESIM, hasCta: false },
      { value: InformationCardsEnum.SCAN_ID_MBB, hasCta: false },
    ],
  },
  {
    productClass: 'TV',
    productFamily: 'TV',
    deliveryDays: 0,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 1,
      },
    ],
    detailsFieldsToHide: [
      DetailsFields.SCAN_ID_CTA,
      DetailsFields.NATIONALITY,
      DetailsFields.ID_TYPE,
      DetailsFields.ID_NUMBER,
      DetailsFields.ID_EXPIRY_DATE,
      DetailsFields.OTO_ID,
      DetailsFields.ESIM,
      DetailsFields.ACTIVATION_DATE,
    ],
    informationCards: [{ value: InformationCardsEnum.USE_YOUR_TV_LOGIN, hasCta: false }],
  },
  {
    productClass: 'POSTPAID',
    productFamily: 'MOBILE',
    deliveryDays: 0,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 1,
      },
      {
        path: 'phone-number',
        substepsQty: 1,
        substepsQtyLoggedIn: 1,
      },
    ],
    detailsFieldsToHide: [DetailsFields.ACTIVATION_DATE, DetailsFields.OTO_ID, DetailsFields.ESIM],
    informationCards: [
      { value: InformationCardsEnum.ESIM, hasCta: false },
      { value: InformationCardsEnum.SCAN_ID, hasCta: false },
      { value: InformationCardsEnum.ROAMING_LIMIT, hasCta: false },
    ],
  },
  {
    productClass: 'PREPAID',
    productFamily: 'MOBILE',
    deliveryDays: 0,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 1,
      },
      {
        path: 'phone-number',
        substepsQty: 1,
        substepsQtyLoggedIn: 1,
      },
    ],
    detailsFieldsToHide: [DetailsFields.ACTIVATION_DATE, DetailsFields.OTO_ID, DetailsFields.ESIM],
    informationCards: [
      { value: InformationCardsEnum.ESIM, hasCta: false },
      { value: InformationCardsEnum.SCAN_ID, hasCta: false },
      { value: InformationCardsEnum.ROAMING_LIMIT, hasCta: false },
    ],
  },
];

export const posCheckoutFlows: CheckoutFlow[] = [
  {
    productClass: 'HFC',
    productFamily: 'INTERNET',
    deliveryDays: 3,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 2,
      },
      {
        path: 'contract-signature',
        substepsQty: 1,
        substepsQtyLoggedIn: 1,
      },
    ],
    detailsFieldsToHide: [
      DetailsFields.SCAN_ID_CTA,
      DetailsFields.NATIONALITY,
      DetailsFields.ID_TYPE,
      DetailsFields.ID_NUMBER,
      DetailsFields.ID_EXPIRY_DATE,
      DetailsFields.OTO_ID,
      DetailsFields.ESIM,
    ],
    informationCards: [
      { value: InformationCardsEnum.CANCEL_PREVIOUS_CONTRACT, hasCta: false },
      { value: InformationCardsEnum.USE_YOUR_TV_LOGIN, hasCta: false },
    ],
  },
  {
    productClass: 'FIBER',
    productFamily: 'INTERNET',
    deliveryDays: 15,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 2,
      },
      {
        path: 'contract-signature',
        substepsQty: 1,
        substepsQtyLoggedIn: 1,
      },
    ],
    detailsFieldsToHide: [
      DetailsFields.SCAN_ID_CTA,
      DetailsFields.NATIONALITY,
      DetailsFields.ID_TYPE,
      DetailsFields.ID_NUMBER,
      DetailsFields.ID_EXPIRY_DATE,
      DetailsFields.ESIM,
    ],
    informationCards: [
      { value: InformationCardsEnum.CANCEL_PREVIOUS_CONTRACT, hasCta: false },
      { value: InformationCardsEnum.USE_YOUR_TV_LOGIN, hasCta: false },
      {
        value: InformationCardsEnum.SET_YOUR_OTO_ID_AVAILABLE,
        hasCta: true,
        ctaButtonName: 'set_oto_id_in_selfcare',
        ctaHref: 'set_oto_id_href',
      },
      {
        value: InformationCardsEnum.SET_YOUR_OTO_ID_UNAVAILABLE,
        hasCta: false,
      },
    ],
  },
  {
    productClass: 'MBB',
    productFamily: 'INTERNET',
    deliveryDays: 0,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 1,
      },
      {
        path: 'contract-signature',
        substepsQty: 1,
        substepsQtyLoggedIn: 1,
      },
    ],
    detailsFieldsToHide: [DetailsFields.ACTIVATION_DATE, DetailsFields.OTO_ID, DetailsFields.ESIM],
    informationCards: [{ value: InformationCardsEnum.SCAN_ID_MBB, hasCta: false }],
  },
  {
    productClass: 'POSTPAID',
    productFamily: 'INTERNET',
    deliveryDays: 0,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 2,
      },
      {
        path: 'contract-signature',
        substepsQty: 1,
        substepsQtyLoggedIn: 1,
      },
    ],
    detailsFieldsToHide: [DetailsFields.ACTIVATION_DATE, DetailsFields.OTO_ID],
    informationCards: [
      { value: InformationCardsEnum.ESIM, hasCta: false },
      { value: InformationCardsEnum.SCAN_ID_MBB, hasCta: false },
    ],
  },
  {
    productClass: 'TV',
    productFamily: 'TV',
    deliveryDays: 0,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 1,
      },
      {
        path: 'contract-signature',
        substepsQty: 1,
        substepsQtyLoggedIn: 1,
      },
    ],
    detailsFieldsToHide: [
      DetailsFields.SCAN_ID_CTA,
      DetailsFields.NATIONALITY,
      DetailsFields.ID_TYPE,
      DetailsFields.ID_NUMBER,
      DetailsFields.ID_EXPIRY_DATE,
      DetailsFields.OTO_ID,
      DetailsFields.ESIM,
      DetailsFields.ACTIVATION_DATE,
    ],
    informationCards: [{ value: InformationCardsEnum.USE_YOUR_TV_LOGIN, hasCta: false }],
  },
  {
    productClass: 'POSTPAID',
    productFamily: 'MOBILE',
    deliveryDays: 0,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 1,
      },
      {
        path: 'phone-number',
        substepsQty: 1,
        substepsQtyLoggedIn: 1,
      },
      {
        path: 'contract-signature',
        substepsQty: 1,
        substepsQtyLoggedIn: 1,
      },
    ],
    detailsFieldsToHide: [DetailsFields.ACTIVATION_DATE, DetailsFields.OTO_ID, DetailsFields.ESIM],
    informationCards: [
      { value: InformationCardsEnum.ESIM, hasCta: false },
      { value: InformationCardsEnum.SCAN_ID, hasCta: false },
      { value: InformationCardsEnum.ROAMING_LIMIT, hasCta: false },
    ],
  },
  {
    productClass: 'PREPAID',
    productFamily: 'MOBILE',
    deliveryDays: 0,
    steps: [
      {
        path: 'details',
        substepsQty: 2,
        substepsQtyLoggedIn: 1,
      },
      {
        path: 'phone-number',
        substepsQty: 1,
        substepsQtyLoggedIn: 1,
      },
      {
        path: 'contract-signature',
        substepsQty: 1,
        substepsQtyLoggedIn: 1,
      },
    ],
    detailsFieldsToHide: [DetailsFields.ACTIVATION_DATE, DetailsFields.OTO_ID, DetailsFields.ESIM],
    informationCards: [
      { value: InformationCardsEnum.ESIM, hasCta: false },
      { value: InformationCardsEnum.SCAN_ID, hasCta: false },
      { value: InformationCardsEnum.ROAMING_LIMIT, hasCta: false },
    ],
  },
];
