import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
  selector: 'lib-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  standalone: true,
  imports: [DynamicHooksComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleButtonComponent extends FormFieldBaseComponent {
  @Output() toggleChange = new EventEmitter<boolean>();

  onToggle() {
    this.control.patchValue(!this.control.value);
    this.toggleChange.emit(this.control.value);
  }
}
