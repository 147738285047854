import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { filter } from 'rxjs';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';
import { TranslatePipe } from 'translate';
import { FormFieldBaseComponent } from '../form-field-base.component';
import { AutocompleteFilterPipe } from './autocomplete-filter.pipe';

export type AutocompleteOption = {
  value: unknown;
  label: string;
};

@Component({
  selector: 'lib-autocomplete-form-field',
  templateUrl: './autocomplete-form-field.component.html',
  styleUrls: ['./autocomplete-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    SvgComponent,
    AutocompleteFilterPipe,
    TranslatePipe,
  ],
  providers: [AutocompleteFilterPipe],
})
export class AutocompleteFormFieldComponent extends FormFieldBaseComponent implements OnInit, OnChanges {
  ngControl = inject(NgControl, { optional: true, self: true });
  protected brandService = inject(BrandService);
  private autocompleteFilterPipe = inject(AutocompleteFilterPipe);
  @Input() options: AutocompleteOption[];
  @Input() selected = false;
  @Input() loading = false;
  @Output() optionSelected = new EventEmitter<AutocompleteOption>();
  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();
  autocompleteControl = new FormControl();

  ngOnInit(): void {
    super.ngOnInit();
    this.control.valueChanges.pipe(filter(Boolean)).subscribe(value => {
      this.setAutoCompleteControlValue(value);
    });
  }

  ngOnChanges() {
    this.setAutoCompleteControlValue(this.control.value);
  }

  private setAutoCompleteControlValue(value: string) {
    if (!this.options) return;
    const option = this.options.find(option => option.value === value);
    this.autocompleteControl.setValue(option?.label || '');
  }

  onOptionSelected(option: AutocompleteOption) {
    this.selected = !!option;
    this.optionSelected.emit(option);
    this.onChange(option?.value || null);
    this.autocompleteControl.setValue(option?.label || '');
    this.control.setValue(option?.value || null);
  }

  onClosed() {
    const validOptions = this.autocompleteFilterPipe.transform(this.options, this.autocompleteControl.value);
    if (validOptions?.length === 1) {
      this.onOptionSelected(validOptions[0]);
    } else {
      const option = this.options.find(option => option.label === this.autocompleteControl.value);
      if (!option) {
        this.onOptionSelected(null);
      }
    }
    this.closed.emit();
  }
}
