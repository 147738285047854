import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';
@Component({
  selector: 'lib-checkout-loading-bar',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule, SvgComponent],
  templateUrl: './checkout-loading-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutLoadingBarComponent {
  brand = inject(BrandService);
  @Input() progress: number;
}
