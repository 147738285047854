<div class="!fixed z-40 w-screen h-screen bg-[--basic-white] top-0"></div>

<div class="blur-none z-40 !fixed w-full h-full">
  <div class="flex items-center flex-col mt-[134px] md:mt-28">
    <lib-svg
      [svg]="brand.isYallo() ? 'air-drop-animated' : 'air-drop-animated-lebara'"
      class="[&_svg]:md:w-[179px] [&_svg]:md:h-[179px] [&_svg]:size-[6rem]"
    ></lib-svg>
  </div>

  <mat-progress-bar
    mode="determinate"
    [ngClass]="{
      '!outline-[--color-primary] text-primary ': brand.isYallo(),
      '!outline-[--color-blue] text-[--color-blue] ': brand.isLebara(),
    }"
    class="!h-[2px] !rounded bg-white mx-auto mt-8 md:mt-13 mb-4 md:mb-6 outline outline-offset-2 !w-1/2 md:!w-1/3"
    [value]="progress"
  ></mat-progress-bar>
</div>
