@if (!hideCloseButton()) {
  <button
    class="z-10 bg-white p-2 rounded-full hover:rotate-90 transition-all duration-200 outline-0 absolute"
    [ngClass]="{
      'top-2 right-2 opacity-75 focus:!border-[--color-dark-gray] border-2 border-[--color-gray-opacity-strong]':
        closePosition() === 'inside',
      'top-[-0.7rem] right-[-0.5rem] shadow-lg opacity-100': closePosition() === 'outside',
    }"
    mat-dialog-close
    data-testid="close-basket"
  >
    <lib-svg class="black xl:w-5 xl:h-5" svg="close"></lib-svg>
  </button>
}
<ng-content select="[modal-header]"></ng-content>
<div>
  <ng-content select="[modal-body]"></ng-content>
</div>
<div>
  <ng-content select="[modal-footer]"></ng-content>
</div>
