export interface AgentDetails {
  sub: string;
  method: string;
  brand: string;
  value: string;
  platform: string;
  ip: string;
  details: {
    username: string;
    agentId: string;
    firstName: string;
    lastName: string;
    email: string;
    forcePasswordChange: number;
    rights: AgentPermissions[];
    isLocked: number;
    partnerId: string;
    shopId: string;
    channel: string;
    tempChannel: string | null;
  };
  type: string;
  parent: string;
  iat: number;
  exp: number;
  iss: string;
}

type AgentPermissions = (typeof agentPermissions)[number];

export const agentPermissions = [
  'SELL_DEVICES',
  'MANAGE_SAVING_OPTIONS',
  'CONTRACT_EXTENSION',
  'POSTPAID_SIGNUP',
  'PREPAID_SIGNUP',
  'PRE_TO_POST',
  'LEAD_GENERATION',
  'PREPAID_RATEPLAN_CHANGE',
  'PRE_TO_POSTPAID_CONVERSION',
  'POSTPAID_CONTRACT_EXTENSION',
  'MANAGE_CUSTOMER_DATA',
  'OPTIONS_MGMT',
  'DEVICE_OPTIONS',
] as const;

export enum Channel {
  os = 'Own Shop',
  ol = 'Online',
  ob = 'Outbound',
  cs = 'Care 2 Sales',
  et = 'Ethnic',
  mt = 'Mobilezone Ticino',
  so = 'UPC Service Point',
  re = 'Retention',
  op = 'Online Partner',
  fm = 'Fust Temp Campaign',
  pt = 'Die Post',
  rr = 'YOL Sunrise Retention',
  sc = 'Sunrise Centers',
  sm = 'Sunrise mobile stores',
  mm = 'Media Markt',
  va = 'Valora',
  nv = 'Naville',
  dc = 'Digitec',
  al = 'Alternative',
  mz = 'Mobilezone',
  mw = 'Mobilezone Romandie',
  mo = 'Mobilezone Promoters',
  ib = 'Inbound',
  mi = 'Migros',
  fu = 'Fust',
  ss = 'STS (Duty Free, Event)',
  id = 'Interdiscount',
  yb = 'Yallo Back Office',
  ab = 'Ambassador',
  ps = 'Partner support',
  es = 'Espas',
  rt = 'Specialist Dealer',
  pd = 'Post shop in shop',
  fh = 'Fachhandel',
  sb = 'SBB',
}
