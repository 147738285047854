<button
  [ngClass]="{ 'opacity-40': disabled() }"
  [disabled]="disabled()"
  [attr.data-testid]="testId()"
  (click)="onButtonClick($event)"
>
  <p
    class="bg-gradient-to-r from-[--color-primary] to-[--color-primary-2] to-80% inline-block text-transparent bg-clip-text font-bold"
  >
    {{ text() }}
  </p>
</button>
