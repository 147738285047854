import { inject, Injectable } from '@angular/core';
import {
  CreditCheckService,
  CheckoutService as MsCheckoutService,
  MsisdnSearchService,
  PdfService,
} from '@yol-digital/ms-client';
import { Observable } from 'rxjs';
import { CustomerLookupService } from 'customer-lookup';
import { LanguageService } from 'language';
import { CheckoutDataAccessService } from '../checkout-data-access.service';
import DocumentCategoryEnum = MsCheckoutService.DocumentCategoryEnum;
import PdfApi = PdfService.Api;
import CheckoutApi = MsCheckoutService.Api;
import CreditCheckApi = CreditCheckService.Api;

@Injectable()
export class PosCheckoutDataAccessService extends CheckoutDataAccessService {
  private readonly pdf = new PdfApi(this.newMicroserviceEndpoint, this.http);
  protected readonly checkout = new CheckoutApi(this.newMicroserviceEndpoint, this.http);
  private readonly creditCheck = new CreditCheckApi(this.newMicroserviceEndpoint, this.http);
  languageService = inject(LanguageService);
  customerLookupService = inject(CustomerLookupService);

  currentStateById(): Observable<MsCheckoutService.CheckStateResp | { [key: string]: string }> {
    throw new Error('Method not implemented.');
  }

  confirmNumber(): Observable<string> {
    throw new Error('Method not implemented.');
  }

  changeNewNumber(): Observable<string> {
    throw new Error('Method not implemented.');
  }

  getProviders(): Observable<MsisdnSearchService.ProvidersResp[] | { [key: string]: string }> {
    throw new Error('Method not implemented.');
  }

  confirmPorting(): Observable<void | { [key: string]: string }> {
    throw new Error('Method not implemented.');
  }

  updateSIMType(): Observable<MsCheckoutService.UpdateSimResp | { [key: string]: string }> {
    throw new Error('Method not implemented.');
  }

  validatePortability(): Observable<{ [key: string]: string }> {
    throw new Error('Method not implemented.');
  }

  submitOrder(): Observable<MsCheckoutService.SubmitOrderResp | { [key: string]: string }> {
    return this.checkout.pos.submitPosOrder({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }

  doCreditCheck(): Observable<{ [key: string]: string }> {
    return this.creditCheck.pos.doCreditCheck({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }

  confirmPersonalIdDetails(): Observable<{ [key: string]: string }> {
    throw new Error('Method not implemented.');
  }

  addEmail(email: string): Observable<{ [key: string]: string }> {
    return this.checkout.pos.addPosEmail({
      checkoutSessionId: this.checkoutSessionService.sessionId,
      email,
    });
  }

  public createSessionId(sessionRequest: MsCheckoutService.SessionReq) {
    return this.checkout.pos.createPosSessionIdV1(sessionRequest);
  }

  public resetCustomerDetails() {
    return this.checkout.pos.resetCustomerDetails({
      checkoutSessionId: this.checkoutSessionService.sessionId,
    });
  }

  public addProduct(products: MsCheckoutService.ProductReq['products']) {
    return this.checkout.pos.addPosProduct({ checkoutSessionId: this.checkoutSessionService.sessionId, products });
  }

  public addSign(category: DocumentCategoryEnum, document?: string) {
    return this.checkout.pos.posAddSign({
      checkoutSessionId: this.checkoutSessionService.sessionId,
      category,
      ...(document && { document }),
    });
  }

  public addLineCheckRef(
    lineCheckRefId: MsCheckoutService.MapDetailsReqLineCheckReq['lineCheckRefId'],
    wishDate: MsCheckoutService.MapDetailsReqLineCheckReq['wishDate']
  ) {
    const lineCheckReq = {
      checkoutSessionId: this.checkoutSessionService.sessionId,
      lineCheckRefId,
      wishDate,
    };

    return this.checkout.pos.addPosLineCheckRef(lineCheckReq);
  }

  public selfcareAddAccountIdV1() {
    return this.checkout.pos.posAddAccountId({
      checkoutSessionId: this.checkoutSessionService.sessionId,
      searchRefId: this.customerLookupService.customerRefIdFromStorage,
    });
  }

  public setPersonalId(): Observable<{ [key: string]: string }> {
    return this.checkout.pos.setPosPersonalId({
      checkoutSessionId: this.checkoutSessionService.sessionId,
      searchRefId: this.customerLookupService.customerRefIdFromStorage,
    });
  }

  public eshopSubmitOrder() {
    return this.checkout.pos.submitPosOrder({
      checkoutSessionId: this.checkoutSessionService.sessionId,
    });
  }

  public confirmCustomerDetails() {
    return this.checkout.pos.confirmCustomerDetails({
      checkoutSessionId: this.checkoutSessionService.sessionId,
    });
  }

  public updateAddressDetails(customerAddress: MsCheckoutService.UpdateDetailsReq) {
    return this.checkout.pos.posUpdateAddressDetailsV1(customerAddress);
  }

  public addCustomerDetails(customerData: Omit<MsCheckoutService.UpdateCustomerDetailsReq, 'checkoutSessionId'>) {
    return this.checkout.pos.addCustomerDetails({
      checkoutSessionId: this.checkoutSessionService.sessionId,
      ...customerData,
    });
  }

  public sendSignLink(phoneNumber: string) {
    return this.checkout.pos.sendSignLink({ checkoutSessionId: this.checkoutSessionService.sessionId, phoneNumber });
  }

  public generateContract() {
    return this.pdf.pos.generateContract({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }

  public generatePoa() {
    return this.pdf.pos.generatePoa({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }

  public getUnsignedDocument(category: MsCheckoutService.UnsignedDocumentCategoryEnum) {
    return this.checkout.pos.getUnsignedDocument(this.checkoutSessionService.sessionId, category);
  }

  public getSignStatus() {
    return this.checkout.pos.signStatus({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }
}
