<lib-modal>
  <div modal-body class="rounded-2xl border p-2 shadow-lg bg-white">
    <lib-pdf-viewer
      [pdf]="pdf()"
      cssClass="w-[60vw] h-[70vh] block"
      [showAllPages]="true"
      [showBorders]="false"
    ></lib-pdf-viewer>
  </div>
</lib-modal>
