export enum CHECKOUT_ERRORS {
  LINECHECK_REF_ID_MISSING = 'ERR_LINECHECK_REF_ID_MISSING',
  CUSTOMER_FOUND_LOGIN_REQUIRED = 'ERR_CUSTOMER_FOUND_LOGIN_REQUIRED',
  CREDITCHECK_LIGHT_PRODUCT_ONLY = 'ERR_CREDITCHECK_LIGHT_PRODUCT_ONLY',
  INVALID_CREDIT_CHECK_DATA = 'ERR_INVALID_CREDIT_CHECK_DATA',
  CREDITCHECK_BAD_CUSTOMER = 'ERR_CREDITCHECK_BAD_CUSTOMER',
  CREDITCHECK_MANUAL_CHECK_REQUIRED = 'ERR_CREDITCHECK_MANUAL_CHECK_REQUIRED',
  CREDITCHECK_ALLOWED_SUB_EXCEEDED = 'ERR_CREDITCHECK_ALLOWED_SUB_EXCEEDED',
  PREPAID_MAX_REACHED = 'ERR_PREPAID_MAX_REACHED',
  ERR_MISSING_DATA = 'ERR_MISSING_DATA',
  ERR_EMAIL_EXISTS = 'ERR_EMAIL_EXISTS',
  ERR_INVALID_EMAIL = 'ERR_INVALID_EMAIL',
  ERR_JSON_VALIDATION = 'ERR_JSON_VALIDATION',
  ERR_NO_DOCUMENT_FOUND = 'ERR_NO_DOCUMENT_FOUND',
}

export enum DETAILS_FIELDS_FROM_MS {
  EMAIL = 'email',
  CONTACT_PHONE = 'contactPhone',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  BIRTHDAY = 'birthday',
  SHIPPING_ADDRESS_CO = 'shippingAddress.co',
  BILLING_ADDRESS_CO = 'billingAddress.co',
  OTO_ID = 'otoId',
}

export enum DetailsFields {
  EMAIL = 'email',
  PHONE = 'phone',
  SCAN_ID_CTA = 'scanIdCTA',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  DATE_OF_BIRTH = 'dateOfBirth',
  NATIONALITY = 'nationality',
  ID_TYPE = 'idType',
  ID_NUMBER = 'idNumber',
  ID_EXPIRY_DATE = 'idExpiryDate',
  BILLING_ADDRESS = 'billingAddress',
  SHIPPING_ADDRESS = 'shippingAddress',
  ACTIVATION_DATE = 'activationDate',
  OTO_ID = 'OTO',
  ESIM = 'ESIM',
}
