import { UrlSegment } from '@angular/router';

const loginPaths = ['login', 'app-login'];

/** Checks whether a given url has the same
 * domain as the host. Different subdomains
 * are not considered internal.
 * Relative links (without a host) are considered internal.
 * @param url the url to check
 * @param host the host to check against
 */
export const isInternal = (url: string, host: string) => {
  if (url.startsWith('/')) return true;
  host = host.replace('www.', '');
  const regex = new RegExp('^(https?://)?(www.)?' + host + '\\S*$', 'gim');
  return regex.test(url);
};

/**
 * Converts a link into a relative link (e.g. yallo.ch/devices -> /devices).
 * Contains a bit of logic to handle urls that don't include
 * the protocol.
 * This is intended to work with links either on our own site
 * or external websites. Might have unexpected results if used for file
 * protocol or such.
 * @param link
 * @param origin current host with protocol, hostname and port if applicable. Needed in rare cases to construct a valid URL
 * @returns
 */
export const makeRelative = (link: string, origin: string) => {
  if (!link) return '';
  if (link.startsWith('/')) return link;
  try {
    const originUrl = new URL(origin.replace('www.', ''));
    let url;
    //if the link includes the origin's host, we know it's not a relative link
    if (link.includes(originUrl.hostname)) {
      const regex = new RegExp(/^https?:\/\//);
      //URL() expects the string to include the protocol, so if the link
      //doesn't include a protocol we add https
      if (!regex.test(link)) {
        link = 'https://' + link;
      }
      url = new URL(link);
    } else {
      url = new URL(link, origin);
    }
    return url.pathname + url.search + url.hash;
  } catch (err) {
    console.warn('🚀 -> makeRelative -> err', err);
    return link;
  }
};

export const isAbsolute = (url: string) => {
  return url.startsWith('http') || url.startsWith('//');
};

export const isLoginPath = (url: UrlSegment[]) => {
  return url.find(item => loginPaths.includes(item.path));
};

export function buildUrl(baseUrl: string, queryParamsObj: { [key: string]: string } = {}): string {
  // Convert queryParamsObj to a query string
  const queryString = Object.keys(queryParamsObj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParamsObj[key])}`)
    .join('&');

  // Check if baseUrl already has a query string or ends with a '?' or '&'
  const separator = baseUrl.includes('?') ? '&' : '?';

  // Return the full URL with query string
  return `${baseUrl}${queryString ? separator + queryString : ''}`;
}
