import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const fiberLineValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  if (!value) {
    return null;
  }

  const pattern = /^[A|B](?:\.\d{3}){3}(?:\.\d|\.X)$/;
  const valid = pattern.test(value.trim());
  return valid ? null : { fiberLine: true };
};
