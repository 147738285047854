<div class="w-full flex justify-between px-4 -mt-3" data-testid="time-remaining">
  @if (
    {
      timeRemaining: timerService.timeRemaining$ | async,
    };
    as $
  ) {
    @if ($.timeRemaining === 0) {
      <mat-hint class="text-xs !text-[var(--color-red-light)]">
        {{ ['login', 'code_expired'] | translate }}
      </mat-hint>
    } @else {
      <mat-hint class="text-xs" [ngClass]="{ '!text-white': isWhiteTheme }">
        {{ ['login', 'time_remaining'] | translate }}
      </mat-hint>
    }
    <mat-hint
      class="text-xs mr-2"
      [ngClass]="{
        '!text-[var(--color-red-light)]': (timerService.timeRemaining$ | async) === 0,
        '!text-white': isWhiteTheme && (timerService.timeRemaining$ | async) > 0,
      }"
    >
      {{ $.timeRemaining | date: 'mm:ss' }}
    </mat-hint>
  }
</div>
