import { FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { fiberLineValidator } from './fiber-line.validator';

export const fiberLineGroupValidator: ValidatorFn = (
  formGroup: FormGroup<{ enterFiberNumberNowOrLater?: FormControl<string>; otoId?: FormControl<string> }>
): ValidationErrors | null => {
  const enterFiberNumberNowOrLater = formGroup.get('enterFiberNumberNowOrLater');

  if (enterFiberNumberNowOrLater.value === 'now') {
    const otoId = formGroup.get('otoId');
    return fiberLineValidator(otoId);
  }

  return null;
};
