<div>
  @if (type() === 'mat-text-button') {
    <button
      mat-button
      disableRipple
      [ngClass]="{ '!shadow-inner-sm': isPressed() }"
      [class]="customButtonClass() + ' sakura-text-button '"
      (click)="onButtonClick($event)"
      [attr.role]="role()"
      [disabled]="disabled()"
    >
      <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
    </button>
  } @else if (type() === 'mat-outlined-button') {
    <button
      mat-stroked-button
      disableRipple
      [ngClass]="{ '!shadow-inner-sm': isPressed() }"
      [class]="customButtonClass() + ' sakura-outlined-button '"
      (click)="onButtonClick($event)"
      [disabled]="disabled()"
    >
      <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
    </button>
  } @else if (type() === 'mat-filled-button') {
    <button
      mat-flat-button
      disableRipple
      [ngClass]="{ '!shadow-inner-sm': isPressed() }"
      [class]="customButtonClass() + ' sakura-filled-button ![background:--color-primary-default] '"
      (click)="onButtonClick($event)"
      [disabled]="disabled()"
    >
      <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
    </button>
  } @else if (type() === 'mat-tonal-button') {
    <button
      mat-flat-button
      disableRipple
      [ngClass]="{ '!shadow-inner-sm': isPressed() }"
      [class]="customButtonClass() + ' sakura-tonal-button '"
      (click)="onButtonClick($event)"
      [attr.role]="role()"
      [disabled]="disabled()"
    >
      <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
    </button>
  }
</div>
<ng-template #buttonTemplate>
  <div [ngClass]="{ 'relative -left-3.5': loading() }" class="flex justify-center items-center">
    @if (loading()) {
      <div
        class="inline-block h-6 w-6 animate-spin rounded-full border-3 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite] mr-1"
        role="status"
      ></div>
    }
    <p class="flex items-center">
      @if (svg()) {
        <lib-svg [class]="svgClass()" class="mr-2" [svg]="svg()"></lib-svg>
      }
      {{ text() }}
    </p>
  </div>
</ng-template>
