import { CommonModule, NgClass } from '@angular/common';
import { APP_ID, ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { App } from 'utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-checkout-skeleton-loading',
  standalone: true,
  imports: [CommonModule, NgClass],
  templateUrl: './checkout-skeleton-loading.component.html',
})
export class CheckoutSkeletonLoadingComponent {
  public isEshop = inject(APP_ID) === App.checkout;
}
