<lib-modal>
  <div class="md:w-[720px]" modal-body data-testid="login-form">
    <lib-login
      [disableMfaOnboarding]="disableMfaOnboarding()"
      [keepPageMetaTitle]="true"
      [email]="email()"
      [redirectPath]="redirectUrl"
    >
      @if (sessionExpired()) {
        <div class="mt-4 text-sm text-center">
          <span> {{ ['login', 'user_session_expired_login_modal_title'] | translate }}</span>
        </div>
      }
      @if (existingUserFirstName()) {
        <div class="mt-4 text-sm text-center">
          {{
            ['login', 'existing_user_please_login'] | translate | replaceToken: { firstName: existingUserFirstName() }
          }}
        </div>
      }
    </lib-login>
  </div>
</lib-modal>
