import { Environment } from './environment.model';

export const environment: Environment = {
  productionMode: true,
  isLocal: false,
  prismicEndpoint: 'https://sunrise-yallo.cdn.prismic.io/api/v2',
  brand: 'yallo',
  fallbackLang: 'de',
  newMicroServiceEndpoint: 'https://ms.yallo.ch',
  websiteUrl: 'https://www.yallo.ch',
  allowedMsEnvs: [],
  eksMSEnvs: ['integration'],
  defaultUrlsEnvironment: '',
  posUrl: 'https://pos.yallo.ch',
  selfcareUrl: 'https://my.yallo.ch',
  featureFlagClientKey: 'sdk-1AyWzT53SObto3SV',
  checkoutUrl: 'https://checkout.yallo.ch',
  sprinklerAppId: '6690eb6f187c274712e20ee8_app_220',
};
