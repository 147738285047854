<div class="relative">
  <ng2-pdfjs-viewer
    [ngClass]="{ invisible: pdfLoading() }"
    [class]="cssClass()"
    [pdfSrc]="base64ToUint8Array(pdf())"
    (onDocumentLoad)="customizeAndShowPdfViewer()"
    viewerId="pdfViewerId"
    viewerFolder="resources/pdfjs"
  ></ng2-pdfjs-viewer>
  @if (pdfLoading()) {
    <div class="absolute top-0 w-full h-full p-12 overflow-hidden">
      <ngx-skeleton-loader class="block w-1/4 my-8 mx-auto" count="1" />
      <ngx-skeleton-loader class="block w-3/4 my-12" count="1" />
      <ngx-skeleton-loader class="grid grid-cols-4 gap-6 md:gap-4" count="16" />
      <ngx-skeleton-loader class="block w-3/4 my-12" count="1" />
      <ngx-skeleton-loader class="grid grid-cols-4 gap-6 md:gap-4" count="16" />
    </div>
  }
</div>
