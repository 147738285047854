import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  LineCheckService,
  CheckoutService as MsCheckoutService,
  MsisdnSearchService,
  OrderHandlerService,
  PdfService,
} from '@yol-digital/ms-client';
import { Observable } from 'rxjs';
import { CheckoutSessionService } from 'checkout-session';
import { ENVIRONMENT_URLS_CONFIG_TOKEN } from 'utils';
import EshopSignReq = MsCheckoutService.EshopSignReq;
import CheckoutApi = MsCheckoutService.Api;
import LineCheckApi = LineCheckService.Api;
import OrderHandlerApi = OrderHandlerService.Api;

export abstract class CheckoutDataAccessService {
  newMicroserviceEndpoint = inject(ENVIRONMENT_URLS_CONFIG_TOKEN).newMicroServiceEndpoint;
  http = inject(HttpClient);
  protected readonly checkout = new CheckoutApi(this.newMicroserviceEndpoint, this.http);
  protected readonly lineCheck = new LineCheckApi(this.newMicroserviceEndpoint, this.http);
  private readonly orderHandler = new OrderHandlerApi(this.newMicroserviceEndpoint, this.http);
  checkoutSessionService = inject(CheckoutSessionService);

  public abstract createSessionId(
    sessionRequest: MsCheckoutService.SessionReq
  ): Observable<{ checkoutSessionId: string } | { error: string }>;

  public checkoutDetailsById(): Observable<MsCheckoutService.CheckoutDetailsV1Resp | { error: string }> {
    return this.checkout.public.checkoutDetailsById(this.checkoutSessionService.sessionId);
  }

  public abstract currentStateById(): Observable<MsCheckoutService.CheckStateResp | { [key: string]: string }>;

  public abstract resetCustomerDetails(): Observable<{ [key: string]: string }>;

  public abstract addProduct(
    products: MsCheckoutService.ProductReq['products']
  ): Observable<MsCheckoutService.BasketResponse | { error: string }>;

  public abstract addSign(
    category: EshopSignReq['category'],
    document?: string
  ): Observable<MsCheckoutService.DocumentResp | { [key: string]: string }>;

  public abstract addLineCheckRef(
    lineCheckRefId: MsCheckoutService.MapDetailsReqLineCheckReq['lineCheckRefId'],
    wishDate: MsCheckoutService.MapDetailsReqLineCheckReq['wishDate']
  ): Observable<MsCheckoutService.LineCheckResp | { error: string }>;

  public lineCheckByOtoId(otoId: string): Observable<LineCheckService.LineCheckResp | { [key: string]: string }> {
    return this.lineCheck.public.lineCheckByOtoId({ otoId });
  }

  public abstract generateContract(): Observable<PdfService.DocumentResp | { [key: string]: string }>;

  public abstract generatePoa(): Observable<PdfService.DocumentResp | { [key: string]: string }>;

  public abstract confirmNumber(msisdn: string): Observable<string>;

  public abstract changeNewNumber(): Observable<string>;

  public abstract getProviders(): Observable<MsisdnSearchService.ProvidersResp[] | { [key: string]: string }>;

  public abstract confirmPorting(
    portRefId: MsisdnSearchService.ConfirmPortReq['portRefId'],
    wishDate?: string
  ): Observable<void | { [key: string]: string }>;

  public abstract updateSIMType(esim: boolean): Observable<MsCheckoutService.UpdateSimResp | { [key: string]: string }>;

  public abstract validatePortability(
    msisdn: string,
    providerId?: string
  ): Observable<MsisdnSearchService.PortValidateResp | { [key: string]: string }>;

  public abstract getUnsignedDocument(
    category?: MsCheckoutService.UnsignedDocumentCategoryEnum
  ): Observable<MsCheckoutService.PdfResp | { [key: string]: string }>;

  public retrieveOrderStatus(
    checkoutSessionId?: string
  ): Observable<OrderHandlerService.OrderStatusRes | { [key: string]: string }> {
    return this.orderHandler.public.retrieveOrderStatus(checkoutSessionId || this.checkoutSessionService.sessionId);
  }

  public abstract selfcareAddAccountIdV1(): Observable<{ checkoutSessionId: string } | { error: string }>;

  public abstract setPersonalId(): Observable<{ [key: string]: string }>;

  public abstract submitOrder(): Observable<MsCheckoutService.SubmitOrderResp | { [key: string]: string }>;

  public abstract doCreditCheck(): Observable<{ [key: string]: string }>;

  public abstract confirmPersonalIdDetails(documentDetails: {
    expireDate?: string;
    number?: string;
    nationality: string;
    typeId: string;
  }): Observable<{ [key: string]: string }>;

  public abstract confirmCustomerDetails(): Observable<{ [key: string]: string }>;

  public abstract updateAddressDetails(
    customerAddress: MsCheckoutService.UpdateDetailsReq
  ): Observable<{ checkoutSessionId: string } | { error: string }>;

  public abstract addCustomerDetails(
    customerData: Omit<MsCheckoutService.UpdateCustomerDetailsReq, 'checkoutSessionId'>
  ): Observable<MsCheckoutService.CustomerDetailsResp | { [key: string]: string }>;

  public abstract addEmail(email: string): Observable<{ [key: string]: string }>;

  public abstract getSignStatus(): Observable<MsCheckoutService.SignStatusResp | { error: string }>;

  public abstract sendSignLink(phoneNumber: string): Observable<void | { error: string }>;
}
