<div class="h-full animate-pulse" [ngClass]="{ 'h-screen w-screen': isEshop }">
  <div [ngClass]="{ 'min-h-screen': isEshop }" class="flex flex-col justify-between">
    @if (isEshop) {
      <div class="opacity-50 w-screen h-16 bg-gray-300 dark:bg-gray-700"></div>
    }
    <div class="opacity-50">
      <div class="lg:mx-48 px-8" [ngClass]="{ 'lg:px-48 ': isEshop }">
        <div class="h-2.5 items-center bg-gray-200 rounded-full dark:bg-gray-700 w-1/3 mx-auto mb-12 mt-6"></div>
        <div class="lg:grid lg:gap-x-4">
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mb-6"></div>
        </div>
        <div class="flex flex-col lg:flex-row">
          <div class="w-full lg:mb-0 lg:mr-6">
            <input
              type="text"
              placeholder="Enter text"
              class="w-full h-12 mb-6 bg-gray-300 border border-gray-300 rounded-lg focus:outline-none placeholder-transparent"
            />
          </div>
          <div class="w-full lg:mb-0">
            <input
              type="text"
              placeholder="Enter text"
              class="w-full h-12 mb-6 bg-gray-300 border border-gray-300 rounded-lg focus:outline-none placeholder-transparent"
            />
          </div>
        </div>
        <div class="lg:grid lg:gap-x-4 mt-4">
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mb-4"></div>
          <!-- radio button -->
          <div class="flex lg:flex-nowrap flex-wrap lg:flex-row mb-6">
            <input type="radio" class="w-5 h-5 bg-gray-300 rounded-full animate-pulse mr-1" />
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-8 mr-4 mt-1"></div>
            <input type="radio" class="w-5 h-5 bg-gray-300 rounded-full p-2 animate-pulse mr-1" />
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-8 mr-4 mt-1"></div>
          </div>
          <div class="flex flex-col lg:flex-row">
            <div class="w-full lg:mb-0 lg:mr-6">
              <input
                type="text"
                placeholder="Enter text"
                class="w-full h-12 mb-6 bg-gray-300 border border-gray-300 rounded-lg focus:outline-none placeholder-transparent"
              />
            </div>
            <div class="w-full lg:mb-0">
              <input
                type="text"
                placeholder="Enter text"
                class="w-full h-12 mb-6 bg-gray-300 border border-gray-300 rounded-lg focus:outline-none placeholder-transparent"
              />
            </div>
          </div>
          <div class="lg:grid lg:grid-cols-2 gap-x-6">
            <input
              type="text"
              placeholder="Enter text"
              class="w-full h-12 mb-6 bg-gray-300 border border-gray-300 rounded-lg focus:outline-none placeholder-transparent"
            />
          </div>
          <div class="lg:grid lg:gap-x-4 mt-4 hidden md:block">
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mb-4"></div>
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
          </div>
          <!-- toggle button -->
          <div class="relative hidden md:block">
            <div class="flex flex-row mt-32 lg:mt-0">
              <div class="w-16 h-8 bg-gray-300 rounded-full p-1 animate-pulse">
                <div class="w-6 h-6 bg-white rounded-full transform transition-transform"></div>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 m-2"></div>
            </div>
            <div class="flex flex-row mt-2">
              <div class="w-16 h-8 bg-gray-300 rounded-full p-1 animate-pulse">
                <div class="w-6 h-6 bg-white rounded-full transform transition-transform"></div>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 m-2"></div>
            </div>
          </div>
          <div class="lg:grid lg:grid-cols-2 gap-x-6 mt-2 hidden md:block">
            <input
              type="text"
              placeholder="Enter text"
              class="w-full h-12 mb-6 bg-gray-300 border border-gray-300 rounded-lg focus:outline-none placeholder-transparent"
            />
          </div>
          <!-- button -->
          <div class="flex flex-col justify-center items-center mt-8 lg:mt-0">
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-96 mt-12 hidden md:block"></div>

            <button class="skeleton-button disabled:opacity-50 disabled:pointer-events-none mt-4">
              <span class="flex justify-center items-center">
                <span class="h-10 w-80 lg:w-96 bg-gray-300 rounded-full mx-auto"></span>
              </span>
            </button>

            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mt-6"></div>
          </div>

          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>
