import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'lib-connected-form-fields',
  standalone: true,
  imports: [],
  templateUrl: './connected-form-fields.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectedFormFieldsComponent {}
