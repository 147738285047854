import { Pipe, PipeTransform } from '@angular/core';
import { AutocompleteOption } from './autocomplete-form-field.component';

@Pipe({
  name: 'autocompleteFilter',
  standalone: true,
})
export class AutocompleteFilterPipe implements PipeTransform {
  transform(options: AutocompleteOption[], filterString: string): AutocompleteOption[] {
    if (!filterString || !options) {
      return options;
    }
    return options.filter(option => option.label.toLowerCase().includes(filterString.toLowerCase()));
  }
}
