import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, signal, ViewChild } from '@angular/core';
import { PdfJsViewerComponent, PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-pdf-viewer',
  standalone: true,
  imports: [PdfJsViewerModule, NgClass, NgxSkeletonLoaderModule],
  templateUrl: './pdf-viewer.component.html',
})
export class PdfViewerComponent {
  cssClass = input('');
  pdf = input('');
  showAllPages = input(false);
  currentPage = input(1);
  showBorders = input(true);
  @ViewChild(PdfJsViewerComponent) public pdfJsViewerComponent: PdfJsViewerComponent;
  pdfLoading = signal(true);

  public base64ToUint8Array(base64: string) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
  }

  customizeAndShowPdfViewer() {
    this.pdfJsViewerComponent.PDFViewerApplication.toolbar.items.container.parentNode.remove();
    this.pdfJsViewerComponent.PDFViewerApplication.appConfig.appContainer.style.setProperty('background', 'none');
    this.pdfLoading.set(false);
  }
}
