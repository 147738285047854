import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMaskDirective } from 'ngx-mask';
import { EMPTY, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'auth-data-access';
import { ButtonFormFieldComponent, FormFieldComponent } from 'form-field';
import { SvgComponent } from 'icon';
import { MsApiResponseErrorCodes } from 'interfaces';
import { LanguageService } from 'language';
import { RedirectService } from 'redirect';
import { TimerComponent, TimerService } from 'timer';
import { ToastService } from 'toast';
import { TranslatePipe, TranslateService } from 'translate';
import { MetaService } from 'utils';

@Component({
  standalone: true,
  imports: [
    TranslatePipe,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    SvgComponent,
    FormFieldComponent,
    ButtonFormFieldComponent,
    TimerComponent,
  ],
  selector: 'lib-login-pos',
  templateUrl: './login-form-pos.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormPosComponent implements OnInit, OnDestroy {
  private toastService = inject(ToastService);
  private authService = inject(AuthService);
  private meta = inject(MetaService);
  private languageService = inject(LanguageService);
  private redirectService = inject(RedirectService);
  private timerService = inject(TimerService);
  private translateService = inject(TranslateService);
  private cdr = inject(ChangeDetectorRef);
  @ViewChild('codeInputField') codeInputField: FormFieldComponent;
  @ViewChild('userNameInputField') userNameInputField: FormFieldComponent;
  @ViewChild('passwordInputField') passwordInputField: FormFieldComponent;
  @Input() username: string;
  @Input() password: string;
  @Input() tempChannel?: string; // Query param
  public loginFormPos: FormGroup;
  public checkIcon?: string | undefined;
  public autocomplete?: string;
  public timeRemaining: number;
  public requestingCode = false;
  public credentialsValid = false;
  private validators = {
    userName: [Validators.required, Validators.pattern(/^[A-Za-zÀ-ÖØ-öø-ÿ0-9_\-.]{4,33}$/)],
    password: [Validators.required, Validators.minLength(8), Validators.maxLength(128)],
    code: [Validators.required, Validators.pattern(/^\d{6}$/)],
  };
  public codeCustomPatterns: NgxMaskDirective['patterns'] = {
    '0': { pattern: new RegExp('[0-9]') },
  };
  public validating = false;
  public maskedPhoneNumber: string;
  private secret: string;
  private subscription: Subscription;

  private onTimeout() {
    this.codeControl.reset();
    this.codeControl.disable();
  }

  ngOnInit() {
    this.loginFormPos = new FormGroup({
      userName: new FormControl(this.username ?? '', this.validators.userName),
      password: new FormControl(this.password ?? '', this.validators.password),
      code: new FormControl(null, this.validators.code),
    });
    this.autocomplete = 'current-password';
    this.setMetaData();

    this.subscription = this.timerService.timeRemaining$.subscribe(n => {
      this.timeRemaining = n;
      if (n === 0) {
        this.onTimeout();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get userNameControl() {
    return this.loginFormPos.get('userName');
  }

  get passwordControl() {
    return this.loginFormPos.get('password');
  }

  get codeControl() {
    return this.loginFormPos.get('code');
  }

  setMetaData() {
    const title = this.translateService.getTranslation(['login_pos', 'title']);
    const description = this.translateService.getTranslation(['login_pos', 'meta_description']);
    this.meta.setTitle(title);
    this.meta.setDescription(description);
  }

  public resendCode(tooManyRequests = false) {
    if (this.validating) return;

    this.codeControl.reset();
    this.requestCode(true);
    const toastMessage = tooManyRequests
      ? this.translateService.getTranslation(['login_pos', 'wrong_code_too_many_times_error_message'])
      : this.translateService.getTranslation(['login_pos', 'code_resent_successfully']);
    this.toastService.add(toastMessage, !tooManyRequests);
  }

  public requestCode(reset = false) {
    if (this.requestingCode) {
      return;
    }
    this.requestingCode = true;

    if (reset) {
      this.timerService.removeTimer();
    }

    this.authService
      .loginPos(this.userNameControl.value, this.passwordControl.value, this.tempChannel)
      .pipe(
        catchError(err => {
          if (!err?.wasCaught) {
            this.onLoginError(err);
          }
          this.requestingCode = false;
          this.cdr.markForCheck();
          return EMPTY;
        })
      )
      .subscribe(res => {
        if (!res.mfa) {
          this.authService
            .validatePos(res.secret)
            .pipe(
              catchError(err => {
                this.validating = false;
                this.codeControl.setValue(null);
                if (!err?.wasCaught) {
                  this.onValidateError(err);
                }
                return EMPTY;
              })
            )
            .subscribe(() => {
              this.requestingCode = false;
              this.toastService.add(this.translateService.getTranslation(['login_pos', 'success']));
              this.redirectService.redirectTo(`${this.languageService.current}/dashboard`);
            });
          return;
        }
        this.maskedPhoneNumber = res.phoneNumber;
        this.secret = res.secret;
        this.credentialsValid = true;
        this.codeControl.enable();
        this.userNameControl.disable();
        this.passwordControl.disable();
        this.requestingCode = false;
        this.timerService.startTimer();
        setTimeout(() => {
          this.codeInputField.focus();
        });
      });
  }

  public login() {
    if (this.validating) return;
    this.validating = true;
    this.authService
      .validatePos(this.secret, this.codeControl.value)
      .pipe(
        catchError(err => {
          this.validating = false;
          this.codeControl.setValue(null);
          if (!err?.wasCaught) {
            this.onValidateError(err);
          }
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.toastService.add(this.translateService.getTranslation(['login_pos', 'success']));
        this.redirectService.redirectTo(`${this.languageService.current}/dashboard`);
      });
  }

  private onLoginError(err: HttpErrorResponse) {
    this.displayErrorMessage(err.error.error);

    return EMPTY;
  }

  private onValidateError(err: HttpErrorResponse) {
    if (err.error.error === 'ERR_TOO_MANY_CODE_REQUESTS') {
      this.resendCode(true);
    } else {
      this.displayErrorMessage(err.error.error);
    }
    return EMPTY;
  }

  public restartLogin() {
    this.timerService.removeTimer();
    this.credentialsValid = false;
    this.userNameControl.enable();
    this.passwordControl.enable();
    setTimeout(() => this.userNameInputField.focus());
  }

  private displayErrorMessage(error: MsApiResponseErrorCodes) {
    const errorKey = error?.toLowerCase() || 'generic';
    const message =
      this.translateService.getTranslation(['login_pos', errorKey]) ??
      this.translateService.getTranslation(['login_pos', 'generic']);
    this.toastService.add(message, false);
  }
}
