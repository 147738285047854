import { APP_ID, inject, Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CheckoutLoadingService } from 'checkout-loading';
import { CheckoutSessionService } from 'checkout-session';
import { LanguageService } from 'language';
import { App } from 'utils';
import { CheckoutFlow } from './checkout-flow.interface';
import { eshopCheckoutFlows, posCheckoutFlows } from './checkout-flows';

@Injectable({
  providedIn: 'root',
})
export class CheckoutFlowService {
  private router = inject(Router);
  private checkoutSessionService = inject(CheckoutSessionService);
  private loadingService = inject(CheckoutLoadingService);
  private languageService = inject(LanguageService);
  private appId = inject(APP_ID, { optional: true });
  public activeSubstepIndex = 0;
  public checkoutFlow: CheckoutFlow;
  private _finishOrder$ = new Subject<number>();
  public readonly finishOrder$: Observable<number> = this._finishOrder$.asObservable();
  private _activeSubstepIndex$ = new BehaviorSubject<number>(0);
  public readonly activeSubstepIndex$: Observable<number> = this._activeSubstepIndex$.asObservable();
  private _loginModalClosed$ = new Subject<boolean>();
  public readonly loginModalClosed$: Observable<boolean> = this._loginModalClosed$.asObservable();
  public activePhoneNumberTab: number | undefined = undefined;
  public newPhoneNumber: string;
  public confirmNewNumberCalled = false;
  public checkoutFlowData: CheckoutFlow[];

  constructor() {
    if (this.appId === App.pos) {
      this.checkoutFlowData = posCheckoutFlows;
    } else {
      this.checkoutFlowData = eshopCheckoutFlows;
    }
  }

  public get desktopView(): boolean {
    return window.innerWidth > 1023;
  }

  private lastStep(stepPath: string): boolean {
    return this.getStepIndex(stepPath) + 1 === this.getTotalSteps();
  }

  public finishingStep(stepPath: string) {
    const activeMobileStep = this.getFirstSubstepIndex(stepPath) + this.activeSubstepIndex + 1;
    return (
      (this.desktopView && this.lastStep(stepPath)) ||
      (!this.desktopView && this.getTotalSubsteps() === activeMobileStep)
    );
  }

  public getStepIndex(stepPath: string): number {
    return this.checkoutFlow?.steps.findIndex(flowStep => flowStep.path == stepPath);
  }

  public getTotalSteps(): number {
    return this.checkoutFlow?.steps.length;
  }

  public getFirstSubstepIndex(stepPath: string): number {
    const stepIndex = this.getStepIndex(stepPath);

    return this.checkoutFlow?.steps
      .slice(0, stepIndex)
      .map(step => (this.checkoutSessionService.isAuthenticated ? step.substepsQtyLoggedIn : step.substepsQty))
      .reduce((prev, curr) => prev + curr, 0);
  }

  public getTotalSubsteps(): number {
    return this.checkoutFlow?.steps
      .map(step => (this.checkoutSessionService.isAuthenticated ? step.substepsQtyLoggedIn : step.substepsQty))
      .reduce((prev, curr) => prev + curr, 0);
  }

  private navigateToNextSubstep(): void {
    this.activeSubstepIndex++;
    this._activeSubstepIndex$.next(this.activeSubstepIndex);
    window.scrollTo(0, 0);
  }

  public navigateToPreviousSubstep(): void {
    this.activeSubstepIndex--;
    this._activeSubstepIndex$.next(this.activeSubstepIndex);
    window.scrollTo(0, 0);
  }

  public navigateToNextStep(currentStepPath: string): void {
    const currentStepIndex = this.getStepIndex(currentStepPath);
    const nextStep = this.checkoutFlow?.steps[currentStepIndex + 1];
    const nextStepPath =
      this.appId === App.checkout
        ? [this.checkoutSessionService.sessionId, nextStep?.path]
        : [this.languageService.current, App.checkout, this.checkoutSessionService.sessionId, nextStep?.path];
    if (!nextStep) {
      this.loadingService.showProgressBarLoading();
      this._finishOrder$.next(this.activePhoneNumberTab);
    } else
      this.router.navigate(nextStepPath).then(() => {
        this.activeSubstepIndex = 0;
        this._activeSubstepIndex$.next(this.activeSubstepIndex);
      });
  }

  public navigateToPreviousStep(currentStepPath: string): void {
    const currentStepIndex = this.getStepIndex(currentStepPath);
    const previousStep = this.checkoutFlow?.steps[currentStepIndex - 1];
    const substepQty = this.checkoutSessionService.isAuthenticated
      ? previousStep.substepsQtyLoggedIn
      : previousStep.substepsQty;
    const urlTree =
      this.appId === App.pos
        ? [this.languageService.current, App.checkout, this.checkoutSessionService.sessionId, previousStep.path]
        : [this.checkoutSessionService.sessionId, previousStep.path];
    this.router.navigate(urlTree).then(() => {
      this.activeSubstepIndex = substepQty - 1;
      this._activeSubstepIndex$.next(this.activeSubstepIndex);
    });
  }

  public navigateToFirstStep(): void {
    const firstStep = this.checkoutFlow?.steps[0];
    this.router.navigate([this.checkoutSessionService.sessionId, firstStep.path]);
  }

  public navigateToFirstSubStep(currentStepPath: string): void {
    const firstStep = this.checkoutFlow?.steps[0];
    const firstStepIndex = this.getStepIndex(firstStep.path);
    const currentStepIndex = this.getStepIndex(currentStepPath);
    if (currentStepIndex === firstStepIndex) {
      this.activeSubstepIndex = 0;
      this._activeSubstepIndex$.next(this.activeSubstepIndex);
    } else this.router.navigate([this.checkoutSessionService.sessionId, firstStep.path]);
  }

  public handleNavigateToNext(stepPath: string) {
    this.loadingService.hideLoading();
    if (this.desktopView) {
      this.navigateToNextStep(stepPath);
      return;
    }
    const stepSubstepsQty = this.checkoutFlow?.steps.find(item => item.path === stepPath).substepsQty;
    const stepSubstepsQtyLoggedIn = this.checkoutFlow?.steps.find(item => item.path === stepPath).substepsQtyLoggedIn;
    const currentStepSubstepsQty = this.checkoutSessionService.isAuthenticated
      ? stepSubstepsQtyLoggedIn
      : stepSubstepsQty;
    const isLastMobileSubstepOfStep = currentStepSubstepsQty === this.activeSubstepIndex + 1;
    if (isLastMobileSubstepOfStep) {
      this.navigateToNextStep(stepPath);
    } else {
      this.navigateToNextSubstep();
    }
  }

  public productHasStep(productClass: string, productFamily: string, stepPath: string): boolean {
    const currentCheckoutFlow = this.checkoutFlowData.find(
      item => item.productClass === productClass && item.productFamily === productFamily
    );
    return !!currentCheckoutFlow.steps.find(item => item.path === stepPath);
  }

  public afterLoginModalClosed(dialogRef: MatDialogRef<unknown, unknown>) {
    dialogRef.afterClosed().subscribe(() => {
      this._loginModalClosed$.next(true);
    });
  }

  public markFormTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormTouched(control as FormGroup);
        return;
      }
      if (control.invalid) {
        this.markControlTouched(control);
      }
    });
  }

  public markControlTouched(control: AbstractControl) {
    control.markAsTouched();
    control.updateValueAndValidity();
  }
}
