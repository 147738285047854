<lib-modal [hideCloseButton]="true" class="p-6">
  <header modal-header>
    <div class="flex justify-center">
      <lib-svg class="normal-plus" svg="fontawesome/triangle-exclamation-o"></lib-svg>
    </div>
    <h2 class="text-center text-2xl font-normal my-4">
      {{ ['pos_modals', 'leave_existing_customer', 'title'] | translate }}
    </h2>
  </header>
  <p class="text-[--neutral-600] text-sm mb-6" modal-body>
    <ngx-dynamic-hooks
      [content]="['pos_modals', 'leave_existing_customer', 'description'] | translate"
      [options]="{ sanitize: false }"
    />
  </p>
  <footer class="flex justify-center gap-2" modal-footer>
    <lib-button
      [text]="['pos_modals', 'leave_existing_customer', 'cta_stay'] | translate"
      type="mat-outlined-button"
      (clickEvent)="closeDialog(false)"
    ></lib-button>
    <lib-button
      [text]="['pos_modals', 'leave_existing_customer', 'cta_leave'] | translate"
      type="mat-filled-button"
      (clickEvent)="closeDialog(true)"
    ></lib-button>
  </footer>
</lib-modal>
