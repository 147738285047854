<div class="flex items-center flex-col m-4">
  <lib-svg
    [svg]="brandService.isYallo() ? 'security-password' : 'security-password-lebara'"
    class="w-32 [&_svg]:size-full"
  ></lib-svg>
</div>

<div class="flex flex-col items-center mt-8 text-wrap">
  <h2 class="font-bold text-base leading-6 text-center">
    {{ ['password', 'success_password_title'] | translate }}
  </h2>

  <p class="mt-4 text-center">
    <ngx-dynamic-hooks
      [content]="['password', 'success_password_disclaimer'] | translate"
      [options]="{ sanitize: false }"
    ></ngx-dynamic-hooks>
  </p>

  <div class="flex flex-col items-center mt-12 w-full">
    <lib-button-form-field
      type="submit"
      size="full-width"
      class="w-full lg:col-span-3 lg:pt-1.5 mt-4"
      data-testid="login-button"
      (click)="redirectAfterLogin()"
      >{{ ['login', 'login_button_label'] | translate }}</lib-button-form-field
    >
  </div>
</div>
