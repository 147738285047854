import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from 'modal';
import { PdfViewerComponent } from 'pdf-viewer';
import { TranslatePipe } from 'translate';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-contract-modal',
  standalone: true,
  imports: [TranslatePipe, ModalComponent, PdfViewerComponent],
  templateUrl: './contract-modal.component.html',
})
export class ContractModalComponent {
  data = inject(MAT_DIALOG_DATA);
  pdf = signal<string>(undefined);

  constructor() {
    const data = this.data;

    this.pdf.set(data?.pdf as string);
  }
}
