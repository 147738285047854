import { ChangeDetectionStrategy, Component, inject, output, OutputEmitterRef } from '@angular/core';
import { BrandService } from 'brand';
import { FeatureFlagService } from 'feature-flag';
import { ButtonFormFieldComponent } from 'form-field';
import { SvgComponent } from 'icon';
import { TranslatePipe } from 'translate';
import { MFAPhase } from '../mfa-phases.enum';

interface PhaseConfig {
  primaryAction: OutputEmitterRef<void> | null;
  primaryTranslationKey: string | null;
  primaryActionTestId: string | null;
  secondaryAction: OutputEmitterRef<void> | null;
  secondaryTranslationKey: string | null;
}

@Component({
  selector: 'lib-create-password-disclaimer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonFormFieldComponent, TranslatePipe, SvgComponent],
  templateUrl: './create-password-disclaimer.component.html',
})
export class CreatePasswordDisclaimerComponent {
  protected brandService = inject(BrandService);
  private featureFlagService = inject(FeatureFlagService);
  public skip = output();
  public createPassword = output();

  private getMFAPhase(): MFAPhase {
    return this.featureFlagService.getFeatureValue('mfa', { phase: MFAPhase.LebaraSkipFirst }).phase;
  }

  public handlePrimaryAction() {
    const { primaryAction } = this.getActionConfig();
    primaryAction?.emit();
  }

  public handleSecondaryAction() {
    const { secondaryAction } = this.getActionConfig();
    secondaryAction?.emit();
  }

  public primaryActionTranslationKey() {
    return this.getActionConfig().primaryTranslationKey;
  }

  public secondaryActionTranslationKey() {
    return this.getActionConfig().secondaryTranslationKey;
  }

  public getPrimaryActionTestId() {
    return this.getActionConfig().primaryActionTestId;
  }

  private getActionConfig() {
    const isYallo = this.brandService.isYallo();

    const phaseConfig: Record<MFAPhase, PhaseConfig> = {
      [MFAPhase.LebaraSkipFirst]: {
        primaryAction: isYallo ? null : this.skip,
        primaryTranslationKey: isYallo ? null : 'skip',
        primaryActionTestId: isYallo ? null : 'skip-button',
        secondaryAction: isYallo ? null : this.createPassword,
        secondaryTranslationKey: isYallo ? null : 'create_password',
      },
      [MFAPhase.LebaraCreatePasswordFirst]: {
        primaryAction: isYallo ? null : this.createPassword,
        primaryTranslationKey: isYallo ? null : 'create_password',
        primaryActionTestId: isYallo ? null : 'create-password-button',
        secondaryAction: isYallo ? null : this.skip,
        secondaryTranslationKey: isYallo ? null : 'skip',
      },
      [MFAPhase.LebaraCreatePasswordFirstYalloSkipFirst]: {
        primaryAction: isYallo ? this.skip : this.createPassword,
        primaryTranslationKey: isYallo ? 'skip' : 'create_password',
        primaryActionTestId: isYallo ? 'skip-button' : 'create-password-button',
        secondaryAction: isYallo ? this.createPassword : this.skip,
        secondaryTranslationKey: isYallo ? 'create_password' : 'skip',
      },
      [MFAPhase.LebaraYalloCreatePasswordFirst]: {
        primaryAction: this.createPassword,
        primaryTranslationKey: 'create_password',
        primaryActionTestId: 'create-password-button',
        secondaryAction: this.skip,
        secondaryTranslationKey: 'skip',
      },
      [MFAPhase.LebaraYalloCreatePasswordOnly]: {
        primaryAction: this.createPassword,
        primaryTranslationKey: 'create_password',
        primaryActionTestId: 'create-password-button',
        secondaryAction: null,
        secondaryTranslationKey: null,
      },
    };

    return phaseConfig[this.getMFAPhase()];
  }
}
