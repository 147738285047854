import { ChangeDetectionStrategy, Component, inject, signal, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginFormComponent } from 'auth-ui';
import { CheckoutSessionService } from 'checkout-session';
import { LanguageService } from 'language';
import { ModalComponent } from 'modal';
import { ReplaceTokenPipe, TranslatePipe } from 'translate';
import { ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig } from 'utils';

@Component({
  selector: 'lib-login-modal',
  templateUrl: './login-modal.component.html',
  standalone: true,
  imports: [ModalComponent, LoginFormComponent, TranslatePipe, ReplaceTokenPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginModalComponent {
  private languageService = inject(LanguageService);
  private checkoutSessionService = inject(CheckoutSessionService);
  data = inject(MAT_DIALOG_DATA);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  @ViewChild(LoginFormComponent, { static: true })
  childComponent: LoginFormComponent;
  detailsPath = `${this.checkoutSessionService.sessionId}/details`;
  selfcareUrl = `${this.config.websiteUrl}/${this.languageService.current}/account`;
  existingUserFirstName = signal('');
  email = signal<string>(null);
  redirectToSelfcare = signal(false);
  sessionExpired = signal(false);
  disableMfaOnboarding = signal<boolean>(null);

  constructor() {
    const data = this.data;

    if (data?.existingUser) this.existingUserFirstName.set(data.firstName);
    if (data?.email) this.email.set(data.email);
    if (data?.redirectToSelfcare) this.redirectToSelfcare.set(data.redirectToSelfcare);
    if (data?.sessionExpired) this.sessionExpired.set(data.sessionExpired);
    if (data?.disableMfaOnboarding) this.disableMfaOnboarding.set(data.disableMfaOnboarding);
  }

  get redirectUrl() {
    return this.redirectToSelfcare() ? this.selfcareUrl : this.detailsPath;
  }
}
