import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export type PasswordRule = {
  regex: RegExp;
  key: string;
};

export const PASSWORD_RULES: PasswordRule[] = [
  { key: 'length', regex: /^.{8,128}$/ },
  { key: 'lowerCase', regex: /[a-z]/ },
  { key: 'upperCase', regex: /[A-Z]/ },
  { key: 'specialChar', regex: /[!@#$%^&*(),.?":{}|<>]/ },
  { key: 'digits', regex: /[0-9]/ },
];

export const passwordStrengthValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;

  const errors: ValidationErrors = {};

  PASSWORD_RULES.forEach(rule => {
    if (!rule.regex.test(value)) errors[rule.key] = true;
  });

  return errors;
};
