import { AbstractControl } from '@angular/forms';
import { format, isMatch, isValid, parse } from 'date-fns';
import { DATE_FORMAT, DATE_MS_FORMAT } from '../app.constants';

export const formatDateToMsFormat = (field: AbstractControl) => {
  if (isMatch(field.value, DATE_FORMAT)) {
    return format(parse(field.value, DATE_FORMAT, new Date()), DATE_MS_FORMAT);
  }
  if (isValid(field.value)) {
    return format(field.value, DATE_MS_FORMAT);
  }
  return field.value;
};
