export const transformPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber?.startsWith('00')) {
    return `+${phoneNumber.substring(2)}`.replace(/ /g, '');
  }
  if (phoneNumber?.startsWith('0')) {
    return `+41${phoneNumber.substring(1)}`.replace(/ /g, '');
  }
  if (phoneNumber?.startsWith('+')) {
    return phoneNumber.replace(/ /g, '');
  }
  return `+${phoneNumber}`.replace(/ /g, '');
};
