<div id="login" class="container p-7 overflow-x-hidden pos-login-form">
  @if (credentialsValid) {
    <div class="flex flex-start cursor-pointer absolute mt-3" role="button" tabindex="0" (click)="restartLogin()">
      <lib-svg class="md:mr-1 mt-4 stroke-dark stroke-[var(--color-dark-gray)]" svg="arrow"></lib-svg>
      <p class="font-bold text-sm hidden md:block mt-3.5">
        {{ ['login_pos', 'back'] | translate }}
      </p>
    </div>
  }

  <div class="max-w-md mx-auto">
    <div class="flex justify-center logo">
      <lib-svg class="logo-gradient [&_svg]:size-16" svg="fontawesome/user-tie"></lib-svg>
    </div>
    <h2
      class="leading-none my-6 text-center text-6xl text-transparent bg-gradient-to-bl bg-clip-text to-[--color-primary] from-[--color-blue-2]"
    >
      {{ ['login_pos', 'title'] | translate }}
    </h2>

    @if (!credentialsValid) {
      <h6 class="text-center my-6 text-xl description text-slate-600">
        {{ ['login_pos', 'login_description'] | translate }}
      </h6>
    }

    @if (credentialsValid) {
      <h6 class="text-center text-nowrap my-2 text-xl description text-slate-600">
        {{ ['login_pos', 'masked_phone_number'] | translate }}
        {{ maskedPhoneNumber }}
      </h6>
      <div class="text-center mb-6">
        <a class="text-primary font-bold cursor-pointer" (click)="resendCode()">
          {{ ['login_pos', 'resend_code'] | translate }}
        </a>
      </div>
    }

    <form [formGroup]="loginFormPos">
      <div class="mb-12 lg:mb-24">
        @if (!credentialsValid) {
          <lib-input-form-field
            #userNameInputField
            class="w-full flex-grow border-solid -mb-4"
            [theme]="'primary'"
            [checkmarkIcon]="checkIcon"
            [autoFocus]="true"
            [label]="['login_pos', 'username_label'] | translate"
            type="text"
            formControlName="userName"
            [placeholder]="['login_pos', 'username_placeholder'] | translate"
            data-testid="userName"
          >
          </lib-input-form-field>
          <lib-input-form-field
            #passwordInputField
            class="w-full flex-grow border-solid -mb-6"
            [theme]="'primary'"
            [label]="['login_pos', 'password_label'] | translate"
            type="password"
            [showHidePassword]="true"
            [checkmarkIcon]="checkIcon"
            id="password"
            [autocomplete]="autocomplete"
            [placeholder]="['login_pos', 'password_placeholder'] | translate"
            formControlName="password"
          >
          </lib-input-form-field>
        }

        @if (credentialsValid) {
          <lib-input-form-field
            class="w-full flex-grow border-solid -mb-6"
            #codeInputField
            [checkmarkIcon]="checkIcon"
            [label]="['login', 'code_label'] | translate"
            maxlength="6"
            type="text"
            id="code"
            formControlName="code"
            data-testid="code-input"
            [mask]="'000000'"
            [maskCustomPatterns]="codeCustomPatterns"
          >
          </lib-input-form-field>
          <lib-timer />
        }
      </div>

      <div class="flex flex-col items-center">
        @if (!credentialsValid) {
          <lib-button-form-field
            type="submit"
            size="full-width"
            class="w-full lg:col-span-3 lg:pt-1.5 disabled:[&>button]:!bg-[--color-gray-2]"
            [disabled]="
              !userNameControl.value || userNameControl.invalid || !passwordControl.value || passwordControl.invalid
            "
            [loading]="requestingCode"
            data-testid="code-request-button"
            (click)="requestCode()"
          >
            {{ ['login_pos', 'code_request'] | translate }}
          </lib-button-form-field>
        } @else {
          @if (timeRemaining === 0) {
            <lib-button-form-field
              type="submit"
              size="full-width"
              class="w-full lg:col-span-3 lg:pt-1.5 disabled:[&>button]:!bg-[--color-gray-2]"
              data-testid="new-code-request-button"
              (click)="requestCode(true)"
            >
              {{ ['login_pos', 'new_code_request'] | translate }}
            </lib-button-form-field>
          }
        }

        @if (credentialsValid && timeRemaining !== 0) {
          <lib-button-form-field
            type="submit"
            size="full-width"
            class="w-full lg:col-span-3 lg:pt-1.5"
            [disabled]="codeControl.invalid"
            [loading]="validating"
            data-testid="login-button-pos"
            (click)="login()"
          >
            {{ ['login_pos', 'login_button_label'] | translate }}
          </lib-button-form-field>
        }
      </div>
    </form>
  </div>
</div>
