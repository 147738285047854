import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { CustomerLookupService } from 'customer-lookup';
import { ModalsService } from 'modal';
import { LeaveExistingCustomerModalComponent } from '../components/leave-existing-customer-modal/leave-existing-customer-modal.component';

export interface CanComponentDeactivate {
  canDeactivate: (
    currentRoute?: ActivatedRouteSnapshot,
    nextState?: RouterStateSnapshot
  ) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CustomerGuard implements CanDeactivate<CanComponentDeactivate> {
  private modalsService = inject(ModalsService);
  private customerLookupService = inject(CustomerLookupService);

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Pass the nextState (destination route) to the component's canDeactivate method
    return component.canDeactivate ? component.canDeactivate(currentRoute, nextState) : true;
  }

  leaveExistingCustomerConfirmation(nextState: RouterStateSnapshot): Observable<boolean> | boolean {
    if (
      nextState.url.includes('checkout') ||
      nextState.url.endsWith('customer') ||
      !this.customerLookupService.hasCustomerSessionActive()
    ) {
      return true;
    }
    const dialogRef = this.modalsService.openDialog(LeaveExistingCustomerModalComponent);

    return dialogRef.afterClosed().pipe(
      tap(leave => {
        if (leave) {
          this.customerLookupService.removeCurrentCustomerFromSession();
        }
      }),
      map(leave => !!leave)
    );
  }
}
