import { Routes } from '@angular/router';
import { featureFlagResolver } from 'feature-flag';
import { BaseLangGuard, langUrlMatcher } from 'language';
import { DummyComponent } from './pages/dummy/dummy.components';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./components/auth-pos/auth-pos.routes').then(m => m.routes) },
  {
    path: '',
    loadChildren: () => import('./pages/pages.routes').then(m => m.routes),
  },
];
export const appRoutes: Routes = [
  {
    matcher: langUrlMatcher,
    component: DummyComponent,
    children: routes,
    resolve: [featureFlagResolver],
  },
  {
    path: '**',
    component: DummyComponent,
    canActivate: [BaseLangGuard],
  },
];
