import { Injectable } from '@angular/core';
import {
  CreditCheckService,
  CheckoutService as MsCheckoutService,
  MsisdnSearchService,
  PdfService,
} from '@yol-digital/ms-client';
import { map, Observable, shareReplay, switchMap } from 'rxjs';
import { CheckoutDataAccessService } from '../checkout-data-access.service';
import EshopSignReq = MsCheckoutService.EshopSignReq;
import CreditCheckApi = CreditCheckService.Api;
import PdfApi = PdfService.Api;
import MsisdnApi = MsisdnSearchService.Api;
import PhoneNumberSearchApi = MsisdnSearchService.Api;

@Injectable()
export class EshopCheckoutDataAccessService extends CheckoutDataAccessService {
  private readonly creditCheck = new CreditCheckApi(this.newMicroserviceEndpoint, this.http);
  private readonly pdf = new PdfApi(this.newMicroserviceEndpoint, this.http);
  private readonly phoneNumberSearch = new MsisdnApi(this.newMicroserviceEndpoint, this.http);
  private readonly msisdn = new PhoneNumberSearchApi(this.newMicroserviceEndpoint, this.http);

  public createSessionId(sessionRequest: MsCheckoutService.SessionReq) {
    return this.checkout.eshop.createSessionIdV1(sessionRequest);
  }

  public currentStateById() {
    return this.checkout.public.currentStateById(this.checkoutSessionService.sessionId);
  }

  public resetCustomerDetails() {
    return this.checkout.eshop.resetCustomerDetails({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }

  public addProduct(products: MsCheckoutService.ProductReq['products']) {
    return this.checkout.eshop.addProduct({ checkoutSessionId: this.checkoutSessionService.sessionId, products });
  }

  public addSign(category: EshopSignReq['category'], document: string = '') {
    const indirectSignFromPosCheckout = !!document;
    if (indirectSignFromPosCheckout) {
      return this.checkout.pos.posAddSign({
        checkoutSessionId: this.checkoutSessionService.sessionId,
        category,
        document,
      });
    }
    return this.checkout.eshop.eshopAddSign({
      checkoutSessionId: this.checkoutSessionService.sessionId,
      category,
    });
  }

  public addLineCheckRef(
    lineCheckRefId: MsCheckoutService.MapDetailsReqLineCheckReq['lineCheckRefId'],
    wishDate: MsCheckoutService.MapDetailsReqLineCheckReq['wishDate']
  ) {
    const lineCheckReq = {
      checkoutSessionId: this.checkoutSessionService.sessionId,
      lineCheckRefId,
      wishDate,
    };

    return this.checkout.eshop.addLineCheckRef(lineCheckReq);
  }

  public generateContract() {
    return this.pdf.eshop.generateContract({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }

  public generatePoa() {
    return this.pdf.eshop.generatePoa({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }

  public confirmNumber(msisdn: string) {
    return this.phoneNumberSearch.eshop
      .confirm({ checkoutSessionId: this.checkoutSessionService.sessionId, msisdn })
      .pipe(map(() => msisdn));
  }

  public changeNewNumber() {
    return this.phoneNumberSearch.eshop
      .availableNumbers({ checkoutSessionId: this.checkoutSessionService.sessionId, count: 1 })
      .pipe(switchMap((res: MsisdnSearchService.MsisdnSearchResp) => this.confirmNumber(res.msisdn[0])));
  }

  public getProviders() {
    return this.msisdn.eshop.portingProviderList().pipe(shareReplay(1));
  }

  public confirmPorting(portRefId: MsisdnSearchService.ConfirmPortReq['portRefId'], wishDate?: string) {
    return this.msisdn.eshop.portConfirm({
      checkoutSessionId: this.checkoutSessionService.sessionId,
      portRefId,
      isDifferentOwner: false,
      activationMode: {
        endOfContract: !wishDate,
        ...(!!wishDate && { wishDate }),
      },
    });
  }

  public updateSIMType(esim: boolean) {
    return this.checkout.eshop.updateSimType({
      checkoutSessionId: this.checkoutSessionService.sessionId,
      esim,
    });
  }

  public validatePortability(msisdn: string, providerId?: string) {
    return this.msisdn.eshop.validate({
      checkoutSessionId: this.checkoutSessionService.sessionId,
      msisdn,
      ...(providerId && { providerId }),
    });
  }

  public getUnsignedDocument() {
    return this.checkout.eshop.getUnsignedDocument(this.checkoutSessionService.sessionId, 'UNSIGNED_CONTRACT');
  }

  public selfcareAddAccountIdV1() {
    return this.checkout.eshop.selfcareAddAccountIdV1({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }

  public setPersonalId() {
    return this.checkout.eshop.setPersonalId({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }

  public submitOrder() {
    return this.checkout.eshop.eshopSubmitOrder({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }

  public doCreditCheck() {
    return this.creditCheck.eshop.eshopDoCreditCheck({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }

  public confirmPersonalIdDetails(documentDetails: {
    expireDate?: string;
    number?: string;
    nationality: string;
    typeId: string;
  }) {
    return this.checkout.eshop.confirmPersonalIdDetails({
      checkoutSessionId: this.checkoutSessionService.sessionId,
      documentDetails,
    });
  }

  public confirmCustomerDetails() {
    return this.checkout.eshop.confirmCustomerDetails({ checkoutSessionId: this.checkoutSessionService.sessionId });
  }

  public updateAddressDetails(customerAddress: MsCheckoutService.UpdateDetailsReq) {
    return this.checkout.eshop.updateAddressDetailsV1(customerAddress);
  }

  public addCustomerDetails(customerData: Omit<MsCheckoutService.UpdateCustomerDetailsReq, 'checkoutSessionId'>) {
    return this.checkout.eshop.addCustomerDetails({
      checkoutSessionId: this.checkoutSessionService.sessionId,
      ...customerData,
    });
  }

  public addEmail(email: string) {
    return this.checkout.eshop.addEmail({
      checkoutSessionId: this.checkoutSessionService.sessionId,
      email,
    });
  }

  public getSignStatus(): Observable<MsCheckoutService.SignStatusResp | { error: string }> {
    throw new Error('Method not implemented.');
  }

  public sendSignLink(): Observable<void | { error: string }> {
    throw new Error('Method not implemented.');
  }
}
