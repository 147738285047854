import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, takeWhile } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckoutLoadingService {
  private _loading$ = new BehaviorSubject({
    cta: false,
    skeleton: false,
    progressBar: false,
  });
  public readonly loading$: Observable<{ cta: boolean; skeleton: boolean; progressBar: boolean }> =
    this._loading$.asObservable();
  public progressValue$ = new BehaviorSubject<number>(0);

  constructor() {}

  public showCTALoading(): void {
    this._loading$.next({ ...this._loading$.value, cta: true });
  }

  public showSkeletonLoading(): void {
    this._loading$.next({ ...this._loading$.value, skeleton: true });
  }

  public hideLoading(): void {
    this._loading$.next({
      cta: false,
      skeleton: false,
      progressBar: false,
    });
  }

  public showProgressBarLoading(): void {
    this.progressValue$.next(0);
    this._loading$.next({ ...this._loading$.value, progressBar: true });
    interval(100)
      .pipe(takeWhile(value => value < 99 && this.progressValue$.value < 98))
      .subscribe(value => this.progressValue$.next(value));
  }
}
