<div class="flex items-center">
  <div class="relative inline-block w-12 h-6 mr-2" (click)="onToggle()">
    <input
      [attr.id]="id + '-input'"
      type="checkbox"
      [checked]="value"
      class="hidden"
      data-testid="shipping-toggle-input"
    />
    <span
      class="absolute cursor-pointer inset-0 rounded-2xl bg-[var(--color-gray-2)] slider transition duration-400"
    ></span>
  </div>
  @if (label) {
    <label [attr.for]="id + '-input'" class="text-[var(--color-gray-1)] field-label font-medium">
      <ngx-dynamic-hooks [content]="label" [options]="{ sanitize: false }"></ngx-dynamic-hooks
    ></label>
  }
</div>
