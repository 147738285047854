<mat-form-field appearance="outline" [class]="class" [subscriptSizing]="desktopView ? 'fixed' : 'dynamic'">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }

  @if (icon) {
    <lib-svg
      [svg]="icon"
      matIconPrefix
      class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] p-3 block box-content"
    ></lib-svg>
  }

  <input
    matInput
    [matAutocomplete]="autocomplete"
    [errorStateMatcher]="errorMatcher()"
    class="text-dark"
    [formControl]="autocompleteControl"
    [type]="type"
    [name]="name"
    [placeholder]="placeholder"
    [size]="size"
    [readonly]="readonly"
    (input)="onInput.emit(autocompleteControl.value)"
    (blur)="_onBlur()"
    (focus)="_onFocus()"
    data-testid="new-input-data"
  />
  @if (labelSuffix) {
    <span aria-atomic="true" matTextSuffix aria-live="polite">{{ labelSuffix }}</span>
  }

  @if (iconSuffix) {
    <lib-svg
      [svg]="iconSuffix"
      matIconSuffix
      class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] p-3 block box-content"
    ></lib-svg>
  }

  @if (loading) {
    <img matIconSuffix src="/resources/small-spinner.gif" class="h-5 ml-2 p-3 block box-content" />
  }

  @if (!loading && ngControl.valid && ngControl.dirty && !ngControl.disabled && ngControl.value && selected) {
    <lib-svg
      matIconSuffix
      [svg]="checkmarkIcon"
      class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto p-3 block box-content"
    ></lib-svg>
  }

  <mat-autocomplete
    autoActiveFirstOption
    #autocomplete="matAutocomplete"
    (optionSelected)="onOptionSelected($event.option.value)"
    (opened)="opened.emit()"
    (closed)="onClosed()"
  >
    @for (option of options | autocompleteFilter: autocompleteControl.value; track option) {
      <mat-option [value]="option">
        {{ option.label }}
      </mat-option>
    }
  </mat-autocomplete>
  @if (
    showErrors() &&
    controlErrorMessage &&
    ((control.errors && control.errors['required'] && !control.value) || (control.invalid && control.value))
  ) {
    <mat-error>
      {{ ['form_errors', controlName, controlErrorMessage] | translate }}
    </mat-error>
  }
</mat-form-field>
