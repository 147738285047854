import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig } from 'utils';
import { setupThreadMetrixScript } from './setup-threatmetrix-script';

/**
 * ThreatMetrix is a service we use to detect possibly fraudulent users.
 * In the frontend, the only thing being done is the profiling, everything else is handled by the backend.
 * The script for this is provided by ThreatMetrix, see
 * https://portal.threatmetrix.eu/kb/index.htm#t=implementation%2Fprofiling%2Fprofiling%20introduction%20and%20integration%2Ftag_obfuscation_ad_blocker_avoidance.htm
 * Also relevant: https://portal.threatmetrix.eu/kb/index.htm#t=implementation%2Fprofiling%2Fprofiling%20introduction%20and%20integration%2Fenhanced_profiling_via_hosted_ssl.htm
 */
declare global {
  interface Window {
    jupiter: {
      go: (url: string, orgId: string, sessionId: string) => void;
      tmx_profiling_complete?: (sessionId: string) => void;
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class ThreatMetrixService {
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  initted = false;
  profiledSessionIds = new Set<string>();

  init() {
    if (this.initted) return;
    this.initted = true;
    setupThreadMetrixScript();

    if (this.config.dev) {
      window.jupiter.tmx_profiling_complete = (sessionId: string) => {
        console.log('ThreatMetrix profiling complete', sessionId);
      };
    }
  }

  public profile(tmxSessionId: string) {
    if (this.profiledSessionIds.has(tmxSessionId)) return;
    this.profiledSessionIds.add(tmxSessionId);
    if (!this.config.threatMetrixConfig) {
      console.warn('ThreatMetrix is not configured on this environment');
      return;
    }
    this.init();
    window.jupiter.go(this.config.threatMetrixConfig.url, this.config.threatMetrixConfig.orgId, tmxSessionId);
  }
}
