import { DatePipe, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Params, UrlTree } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import {
  CheckoutService as MsCheckoutService,
  PosCustomerLookupService,
  SelfcareService,
} from '@yol-digital/ms-client';
import { format, parseISO } from 'date-fns';
import {
  catchError,
  concatMap,
  EMPTY,
  forkJoin,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { CheckoutFlow } from 'checkout-flow';
import { CHECKOUT_ERRORS, DetailsFields } from 'checkout-utils';
import { CustomerLookupService } from 'customer-lookup';
import { PCProduct } from 'product';
import { DATE_FORMAT, DATE_MS_FORMAT } from 'utils';
import { CheckoutBaseService, CheckoutMsResponse } from '../checkout-base.service';

@Injectable({
  providedIn: 'root',
})
export class PosCheckoutBaseService extends CheckoutBaseService {
  protected location = inject(Location);
  protected route = inject(ActivatedRoute);
  protected customerLookupService = inject(CustomerLookupService);

  constructor() {
    super();
    this.checkoutFlowService.finishOrder$.subscribe((activePhoneNumberTab: number) => {
      this.finishOrder(activePhoneNumberTab);
    });
  }

  public startSession(productCode: string, params: Params): Observable<UrlTree | boolean> {
    this.checkoutLoadingService.showSkeletonLoading();
    const lockingPeriodStr: string = params['lockingPeriod'];
    const lineCheckId: string = params['lineCheckId'];
    const promotionCodeFromUrl: string = params['promotion'];

    const getTrackingCookiesCall$ = this.analyticsService.getTrackingCookies(null);
    const loadProductDataCall$ = this.productService.getPCProductByCode(productCode);

    const createSessionCall$ = forkJoin([getTrackingCookiesCall$, loadProductDataCall$]).pipe(
      switchMap(([trackingCookies, loadProductRes]) => {
        const sessionReq = this.createSessionRequest(trackingCookies, params);

        const defaultPromotion = loadProductRes.defaultPromotion?.code;

        return this.checkoutDataAccessService.createSessionId(sessionReq).pipe(
          tap(({ checkoutSessionId }: { checkoutSessionId: string }) =>
            this.checkoutSessionService.setNewSession({ checkoutSessionId })
          ),
          switchMap(() =>
            this.getBackupPromotion(promotionCodeFromUrl, defaultPromotion).pipe(
              switchMap((backupOnlinePromotions: PrismicDocument[] | null) =>
                this.setPromotion(loadProductRes, backupOnlinePromotions, promotionCodeFromUrl, defaultPromotion)
              ),
              tap(({ backupPromotionApplied }) => {
                this.lineCheckRefId = lineCheckId;
                this.productData = loadProductRes;
                this.product = PCProduct.fromMS(loadProductRes);
                this.backupPromotionApplied.set(backupPromotionApplied);
                if (backupPromotionApplied) {
                  this.addAnalyticsEvent('backup_promotion_applied');
                }
              })
            )
          )
        );
      })
    );

    const addProductCall$ = createSessionCall$.pipe(
      mergeMap(({ promotion, promotionCode, backupPromotionApplied }) => {
        const lockingPeriod = this.getLockingPeriod(lockingPeriodStr, promotion, backupPromotionApplied);
        return this.addProduct(productCode, lockingPeriod, promotionCode);
      })
    );

    const setLineCheck$ = addProductCall$.pipe(
      mergeMap(() => {
        if (this.productData.productSpecClass == 'HFC' || this.productData.productSpecClass == 'FIBER') {
          const defaultWishDate = new Date();
          defaultWishDate.setDate(defaultWishDate.getDate() + (this.productData.productSpecClass == 'HFC' ? 3 : 15));
          if (!lineCheckId) {
            this.displayErrorMessage(CHECKOUT_ERRORS.LINECHECK_REF_ID_MISSING);
            setTimeout(() => {
              this.location.back();
            }, 2000);
          }
          return this.addLineCheck(new DatePipe('en-US').transform(defaultWishDate, DATE_MS_FORMAT));
        }
        return of(null);
      })
    );

    const checkUserAuthentication$ = setLineCheck$.pipe(
      mergeMap(() => from(this.authService.getToken())),
      mergeMap(token => {
        const currentUserType = token ? 'existing_user' : 'new_user_funnel';
        this.addAnalyticsEcommerceEvent('begin_checkout', undefined, currentUserType);
        return of(null);
      }),
      catchError(err => {
        this.handleError(err, true);
        return EMPTY;
      }),
      map(() => {
        const urlTree = [this.languageService.current, 'checkout', this.checkoutSessionService.sessionId, 'details'];
        return this.router.createUrlTree(urlTree);
      })
    );

    return checkUserAuthentication$;
  }

  public loadSession(
    checkoutSessionId: string,
    routePath: string,
    callHideLoadingAtTheEnd = true
  ): Observable<CheckoutMsResponse> {
    // dont show skeleton when coming from order submit(from any page) to confirmation
    const hasPreviousNavigation = this.router.lastSuccessfulNavigation?.previousNavigation;
    const showSkeleton = hasPreviousNavigation || routePath !== 'confirmation';
    if (showSkeleton) {
      this.checkoutLoadingService.showSkeletonLoading();
    }
    this.checkoutSessionService.updateSession({ checkoutSessionId });
    const getCheckoutDetails$ = this.checkoutDataAccessService.checkoutDetailsById().pipe(
      tap(res => this.checkoutSessionService.updateSession(res as CheckoutMsResponse)),
      take(1)
    );

    const getCustomerAccountCall$ = this.customerLookupService.accountDetailsBySearchRefId();

    return forkJoin([getCheckoutDetails$, getCustomerAccountCall$]).pipe(
      switchMap(([checkoutDetails, account]: [CheckoutMsResponse, PosCustomerLookupService.AccountResp]) => {
        account
          ? this.checkoutSessionService.updateAccount(account as SelfcareService.AccountResp)
          : this.checkoutSessionService.resetAccount();
        this.checkoutSessionService.updateSession(checkoutDetails);

        if (checkoutDetails.orderStatus === 'IN_CREATION') {
          const setPersonalIdAndFetchDetails$ =
            account && !checkoutDetails.billingCustomerDetails?.accountId
              ? this.checkoutDataAccessService.setPersonalId().pipe(switchMap(() => getCheckoutDetails$))
              : getCheckoutDetails$;
          return setPersonalIdAndFetchDetails$;
        }
        return of(checkoutDetails);
      }),
      switchMap((checkoutDetails: CheckoutMsResponse) =>
        forkJoin([
          of(checkoutDetails),
          this.productService.getPCProductByCode(checkoutDetails?.cartData.products[0].code),
        ])
      ),
      switchMap(([checkoutDetails, productData]) => {
        this.productData = productData;
        this.product = PCProduct.fromMS(this.productData);
        checkoutDetails.cartData.products.forEach(product => {
          this.threatMetrixService.profile(product.tmxSessionId);
        });

        this.lineCheckRefId = checkoutDetails.cartData.products[0]?.lineCheckDetails?.refId;

        this.featureFlagService.setAttributeOverrides({
          checkoutSessionId: this.checkoutSessionService.sessionId,
          productClass: this.checkoutSessionService.productSpecClass,
        });
        this.checkoutFlowService.checkoutFlow = this.checkoutFlowService.checkoutFlowData.find(
          (flow: CheckoutFlow) =>
            flow.productClass == checkoutDetails.cartData.products[0].productSpecClass &&
            flow.productFamily == checkoutDetails.cartData.products[0].productSpecFamily
        );

        if (this.shouldDoIdCheck()) {
          const hasDoneIdCheckInSession = this.checkoutSessionService.idScanSuccessful;
          if (!hasDoneIdCheckInSession) {
            this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.ID_NUMBER);
            this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.ID_EXPIRY_DATE);
          }
        } else {
          this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.SCAN_ID_CTA);
          this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.ID_TYPE);
          this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.NATIONALITY);
          this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.ID_NUMBER);
          this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.ID_EXPIRY_DATE);
        }

        if (callHideLoadingAtTheEnd) {
          this.checkoutLoadingService.hideLoading();
        }
        return of(checkoutDetails);
      }),
      catchError(err => {
        this.handleError(err, true);
        return EMPTY;
      })
    );
  }

  public handleConfirmCustomerDetailsError(err: HttpErrorResponse) {
    if (err?.error.error === CHECKOUT_ERRORS.CUSTOMER_FOUND_LOGIN_REQUIRED) {
      const params: Partial<MsCheckoutService.PersonalDetails> & { email?: string } = {
        firstName: this.checkoutSessionService.personalDetails.firstName,
        lastName: this.checkoutSessionService.personalDetails.lastName,
        birthday: format(parseISO(this.checkoutSessionService.personalDetails.birthday), DATE_FORMAT),
      };

      const message = this.translateService.getTranslation(['pos_checkout_details', 'user_needs_to_login_text']);
      this.toastService.add(message, false, 4000);

      this.router.navigateByUrl(
        this.router.createUrlTree([this.languageService.current, 'customer-find'], {
          queryParams: params,
        })
      );

      return EMPTY;
    }
    this.handleError(err);
    return EMPTY;
  }

  public handleError(err: HttpErrorResponse, navigate = false): Observable<never> {
    this.addAnalyticsEvent('error_occurred', {
      error_message: err?.message,
      error_code: err?.error?.error,
      http_status: '' + err?.status,
    });
    this.displayErrorMessage(err?.error?.error, 'pos_somthing');
    console.error(err);
    if (navigate) {
      const isSessionActive = this.customerLookupService?.hasCustomerSessionActive();
      setTimeout(() => {
        const route = isSessionActive ? 'customer' : 'products';
        this.router.navigate([this.languageService.current, route]);
        this.checkoutLoadingService.hideLoading();
      }, 2000);
    }
    this.checkoutLoadingService.hideLoading();
    return EMPTY;
  }

  updateContactDetails(data: { contactNumber: string }) {
    return this.customerLookupService.updateCustomerContactPhone(data);
  }

  updateUserEmail(email?: string) {
    return this.customerLookupService.updateCustomerEmail(email);
  }

  updateUserBillingAddress(data: PosCustomerLookupService.UpdateBillingAddressReq) {
    return this.customerLookupService.updateCustomerBillingAddress(data);
  }

  public finishOrder(phoneNumberActiveTab?: number) {
    this.addAnalyticsEcommerceEvent('add_payment_info');

    if (this.checkoutSessionService.idScanSuccessful) {
      this.addAnalyticsEvent('scan_id_successful');
    }

    if (this.checkoutSessionService.eSim) {
      this.addAnalyticsEvent('esim_activated');
    }

    const creditCheckCall$ =
      this.checkoutSessionService.productSpecCategory === 'PREPAID'
        ? of(null)
        : this.checkoutDataAccessService.doCreditCheck();

    const callGeneratePOA = this.checkoutSessionService.productSpecFamily === 'MOBILE' && phoneNumberActiveTab === 0;

    const POACalls$ = callGeneratePOA
      ? this.generatePoa().pipe(concatMap(() => this.checkoutDataAccessService.addSign('POA_SIGNATURE')))
      : of(null);

    creditCheckCall$
      .pipe(
        concatMap(() => forkJoin([POACalls$])),
        tap(() => this.checkoutLoadingService.progressValue$.next(98)),
        concatMap(() => this.checkoutDataAccessService.submitOrder()),
        catchError(err => {
          this.checkoutLoadingService.hideLoading();
          if (!err?.wasCaught) {
            return this.handleSubmitOrderErrors(err);
          }
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.addAnalyticsEcommerceEvent('purchase', this.checkoutSessionService.sessionId || '');

        const urlTree = [
          this.languageService.current,
          'checkout',
          this.checkoutSessionService.sessionId,
          'confirmation',
        ];

        this.router.navigate(urlTree);
      });
  }

  protected handleSubmitOrderErrors(err: HttpErrorResponse) {
    if (
      err?.error?.error === CHECKOUT_ERRORS.CREDITCHECK_LIGHT_PRODUCT_ONLY ||
      err?.error?.error === CHECKOUT_ERRORS.INVALID_CREDIT_CHECK_DATA ||
      err?.error?.error === CHECKOUT_ERRORS.CREDITCHECK_BAD_CUSTOMER ||
      err?.error?.error === CHECKOUT_ERRORS.CREDITCHECK_MANUAL_CHECK_REQUIRED ||
      err?.error?.error === CHECKOUT_ERRORS.CREDITCHECK_ALLOWED_SUB_EXCEEDED
    ) {
      this.addAnalyticsEvent('error_credit_check_rejection');
      const urlTree = [
        this.languageService.current,
        'checkout',
        this.checkoutSessionService.sessionId,
        'credit-check-rejection',
      ];

      this.router.navigate(urlTree);
      return EMPTY;
    }

    this.handleError(err);
    return EMPTY;
  }

  public logout(): void {}
}
