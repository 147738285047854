<div [class]="class">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <mat-radio-group
    class="ml-[-9px]"
    color="primary"
    [ngClass]="{ 'flex flex-col': vertical }"
    [formControl]="control"
    (change)="onRadioButtonChange($event)"
    data-testid="identification-radio"
  >
    @for (option of options; track option) {
      <mat-radio-button [value]="option.value" class="mr-2 [&_label]:!pl-0">
        <span class="section-title !font-normal">{{ option.label | translate }}</span>
      </mat-radio-button>
    }
  </mat-radio-group>
</div>
